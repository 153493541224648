import React, { Fragment } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Tab, Nav } from "react-bootstrap"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import NoImage from "../images/catalog-no-image.png"
import "../components/redesign/pages/literature-page.scss"
import { Link } from "gatsby"
import ModalVideo from "../components/literature/ModalVideo"
// import ProductCatalog from "../components/literature/productCatalog"

class LiteratureAndMedia extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    const { catalogs, videos, site, allSanityEvent } = this.props.data

    const siteTitle = site.siteMetadata.title
    const siteDescription = site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle} type="home">
        <SEO title="Literature and Media" description={siteDescription} />
        <div className="page-headline">
          <div className="container">
            <div className="section-heading">
              <h1>Literature and Media</h1>
            </div>
          </div>
        </div>
        <div className="literature-page-wrapper">
          <Tab.Container defaultActiveKey={2}>
            <Nav
              className="home-tabs nav-justified"
              style={{ position: "relative" }}
            >
              {allSanityEvent?.edges.length ? (
                <Nav.Item>
                  <div>
                    <Nav.Link eventKey={1}>
                      <div>News & Events</div>
                    </Nav.Link>
                  </div>
                </Nav.Item>
              ) : null}
              {catalogs?.edges.length ? (
                <Nav.Item>
                  <Nav.Link eventKey={2}>
                    <div>Catalogs & Literature</div>
                  </Nav.Link>
                </Nav.Item>
              ) : null}

              {videos?.edges.length ? (
                <Nav.Item>
                  <Nav.Link eventKey={3}>
                    <div>Videos & Media</div>
                  </Nav.Link>
                </Nav.Item>
              ) : null}
            </Nav>

            <Tab.Content>
              {allSanityEvent.edges.length ? (
                <Tab.Pane eventKey={1}>
                  <LazyLoadComponent>
                    <div className="news-events-wrapper">
                      <div className="container">
                        {allSanityEvent.edges.length ? (
                          <Fragment>
                            <h2 className="text-blue lined-heading">
                              News & Events
                            </h2>
                            {allSanityEvent.edges.map(event => (
                              <div
                                className="news-event-item"
                                key={event.node.id}
                              >
                                <div className="row">
                                  <div className="col-md-3">
                                    <Link to={`/${event.node.slug.current}`}>
                                      <img
                                        src={
                                          event?.node?.mainImage?.asset?.fluid
                                            .src
                                            ? event.node.mainImage.asset.fluid
                                                .src
                                            : "//via.placeholder.com/300x200"
                                        }
                                        className="img-fluid"
                                      />
                                    </Link>
                                  </div>
                                  <div className="col-md-7 pl-md-3 pt-2">
                                    <Link
                                      to={`/${event.node.slug.current}`}
                                      className="news-date"
                                    >
                                      {event.node.title}
                                    </Link>
                                    <p className="news-desc my-3">
                                      {event.node.excerpt}
                                    </p>
                                    <Link
                                      to={`/${event.node.slug.current}`}
                                      className="news-link"
                                    >
                                      Read More
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Fragment>
                        ) : (
                          <h2 className="text-blue lined-heading">
                            No News & Events yet!!
                          </h2>
                        )}
                      </div>
                    </div>
                  </LazyLoadComponent>
                </Tab.Pane>
              ) : null}
              {catalogs?.edges.length ? (
                <Tab.Pane eventKey={2}>
                  <LazyLoadComponent>
                    <div className="catalogs-wrapper">
                      <div className="container">
                        <h2 className="text-blue lined-heading">
                          Catalogs & Literature
                        </h2>
                        <div className="indented">
                          <div className="row">
                            {catalogs?.edges?.map(catalog => (
                              <div
                                className="col-md-4 col-lg-3 mb-5 text-center"
                                key={catalog.node.id}
                              >
                                <div className="catalog-item">
                                  <a
                                    href={catalog.node.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className="catalog-thumb">
                                      {catalog.node.image &&
                                      catalog.node.image.asset &&
                                      catalog.node.image.asset.fluid &&
                                      catalog.node.image.asset.fluid.src ? (
                                        <img
                                          src={
                                            catalog.node.image.asset.fluid.src
                                          }
                                          alt="cover"
                                          height="180px"
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={NoImage}
                                          alt="no cover image"
                                          height="180px"
                                        />
                                      )}
                                    </div>

                                    <span className="catalog-label">
                                      {catalog.node.title}
                                    </span>
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </LazyLoadComponent>
                </Tab.Pane>
              ) : null}
              {videos?.edges.length ? (
                <Tab.Pane eventKey={3}>
                  <LazyLoadComponent>
                    <div className="videos-wrapper" style={{ zIndex: 10 }}>
                      <div className="container">
                        <h2 className="text-blue lined-heading">
                          Product Videos & Media
                        </h2>
                        <div className="row">
                          {videos?.edges?.map(vid => (
                            <ModalVideo
                              key={vid.node.id}
                              videoID={vid?.node?.url}
                              thumbnailImage={
                                vid?.node?.image?.asset?.fluid?.src
                              }
                              videoTitle={vid?.node?.title}
                              videoDesc={vid?.node?.description}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </LazyLoadComponent>
                </Tab.Pane>
              ) : null}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Layout>
    )
  }
}

export default LiteratureAndMedia

export const literatureAndMediaQuery = graphql`
  query literatureAndMediaQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityEvent(
      filter: { whereToBuild: { eq: "live" } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          whereToBuild
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          mainImage {
            asset {
              fluid(maxWidth: 300, maxHeight: 200) {
                src
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
    catalogs: allSanityLiteratureAndMedia(
      filter: { category: { eq: "catalogs" }, whereToPublish: { eq: "live" } }

      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          title
          url
          category
          description
          image {
            asset {
              fluid(maxWidth: 180) {
                src
              }
            }
          }
        }
      }
    }
    videos: allSanityLiteratureAndMedia(
      filter: { category: { eq: "videos" }, whereToPublish: { eq: "live" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          title
          url
          category
          description
          image {
            asset {
              fluid(maxWidth: 300) {
                src
              }
            }
          }
        }
      }
    }
  }
`
